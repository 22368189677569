import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Footer.scss"
import Info from "./Info"
import Logo from "../../assets/icon/neurond-final-white.svg"
import routes from "../../router"
import { Link } from "gatsby"

const teleIcon = `${process.env.GATSBY_CDN_CONFIG}/v2/contact/icon-tele.svg`
const mailIcon = `${process.env.GATSBY_CDN_CONFIG}/v2/contact/icon-mail.svg`
const locationIcon = `${process.env.GATSBY_CDN_CONFIG}/v2/contact/icon-location.svg`
const linkedIn = `${process.env.GATSBY_CDN_CONFIG}/v2/contact/linked-in.png`
const facebook = `${process.env.GATSBY_CDN_CONFIG}/v2/contact/facebook.png`

// const Logo = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/logo/neurond-final-white.svg`

const contactUs = {
  title: "Tel",
  descriptions: "+84 28 3812 0101 (EN)",
  icon: teleIcon,
}

const email = {
  title: "Mail",
  descriptions: "contact@neurond.com",
  icon: mailIcon,
}

const addresses = [
  {
    title: "Head Office",
    descriptions:
      "162 Nguyen Co Thach St., Ngu Hanh Son Dist, Da Nang City, Vietnam",
    icon: locationIcon,
  },
  {
    title: "Ho Chi Minh City Office",
    descriptions:
      "Suite 5.8, 5th Floor, E.town 1 building, 364 Cong Hoa Str, Tan Binh Dist., HCM City, Vietnam",
    icon: "",
  },
  {
    title: "Ho Chi Minh City Office",
    descriptions:
      "408/10 My Gia Quarter, S12 Str, District 7, HCM City, Vietnam",
    icon: "",
  },
  {
    title: "Ha Noi Office",
    descriptions:
      "Dolphin Plaza 28 Tran Binh Str, Nam Tu Liem Dist., Ha Noi City, Vietnam",
    icon: "",
  },
  {
    title: "Hue Office",
    descriptions: "M16, 10 Str, Hue City, Vietnam",
    icon: "",
  },
  {
    title: "Singapore Office",
    descriptions: "91 Bencoolen Str #01-77, Sunshine Plaza S189652, Singapore",
    icon: "",
  },
]

const Footer = ({ isShowContact = true }) => {
  return (
    <footer>
      <div className="info-section">
        <div className="info-section__content wrapper --flex-row">
          <div className="row m-minus-15">
            <div className="col-lg-6 col-12 row info-section__content__contact-info">
              <div className="col-12 px-0">
                <img
                  className="img-fluid logo"
                  alt="neurond logo"
                  src={Logo}
                ></img>
              </div>
              <div className="col-sm-6 col-12 mb-4 px-0">
                <Info {...contactUs} />
              </div>
              <div className="col-sm-6 col-12 mb-4 px-0">
                <Info {...email} />
              </div>
              <div className="col-12 px-0">
                {addresses.map((address, index) => {
                  return <Info {...address} />
                })}
              </div>
            </div>
            <div className="offset-lg-1 col-lg-5 col-12 info-section__content__menu">
              <div className="row">
                <div className="col-4">
                  <p className="info-section__content__menu__heading white">
                    About
                  </p>
                  {routes.map((item, index) => {
                    if (item.active) {
                      if (item.name === "blog" || item.name === "careers") {
                        return (
                          <Link
                            key={index}
                            to={item.path}
                            className="info-section__content__menu__item"
                          >
                            {item.name}
                          </Link>
                        )
                      } else if (item.name === "company") {
                        return item.children.map((child, index) => {
                          if (child.active) {
                            return (
                              <Link
                                key={index}
                                to={child.path}
                                className="info-section__content__menu__item"
                              >
                                {child.name}
                              </Link>
                            )
                          }
                        })
                      }
                    }
                  })}
                </div>
                <div className="col-4">
                  <p className="info-section__content__menu__heading white">
                    Services
                  </p>
                  {routes.map((item, index) => {
                    if (item.active) {
                      if (item.name === "services") {
                        return item.children.map((child, index) => {
                          if (child.active) {
                            return (
                              <Link
                                key={index}
                                to={child.path}
                                className="info-section__content__menu__item"
                              >
                                {child.name}
                              </Link>
                            )
                          }
                        })
                      }
                    }
                  })}
                </div>
                <div className="col-4">
                  <p className="info-section__content__menu__heading white">
                    Product
                  </p>
                  {routes.map((item, index) => {
                    if (item.active) {
                      if (item.name === "products") {
                        return item.children.map((child, index) => {
                          if (child.active) {
                            return (
                              <Link
                                key={index}
                                to={child.path}
                                className="info-section__content__menu__item"
                              >
                                {child.name}
                              </Link>
                            )
                          }
                        })
                      }
                    }
                  })}
                </div>
                <div className="info-section__content__menu__social-links">
                  <Link
                    to="https://www.linkedin.com/company/neurond-ai/"
                    className="d-block pr-4"
                  >
                    <img className="img-fluid" alt="linked-in" src={linkedIn} />
                  </Link>
                  <Link
                    to="https://www.facebook.com/NeurondAI"
                    className="d-block"
                  >
                    <img className="img-fluid" alt="linked-in" src={facebook} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 info-section__content__copyright">
              <p>© 2024, All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
