import React from "react";
import "./Info.scss";
import { Trans } from "gatsby-plugin-react-i18next"

const Info = ({ title, descriptions, icon }) => {
    const isEmail = str => str.includes('@');
    const isEmpty = str => str.length === 0;

    return (
        <div className="info row align-items-center">
        <div className="info__header">
            {
                isEmpty(icon) ? <span></span> : <img className="img-fluid info__header__icon" alt={"info icon" + {title}} src={icon} />
            }
        </div>
        <div className="info__body">
            <p><Trans>{title}</Trans></p>
            {
                isEmail(descriptions) ? <a className="white" href={`mailto:${descriptions}`}>{descriptions}</a> : <p className="white">{descriptions}</p>
            }
        </div>
        </div>
    );
};

export default Info;
