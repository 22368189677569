import React, { createRef } from "react"
import PropTypes from "prop-types"
import {
  useStaticQuery,
  graphql,
  // withPrefix,
  // Link,
  // withAssetPrefix,
} from "gatsby"
import Header from "./Navbar/Navbar"
import Footer from "./Footer/Footer"
import Scroll from "./ScrollTop/Scroll"
import "bootstrap/dist/css/bootstrap.min.css"

export const scrollTop = 250

const Layout = ({ children, isShowContactSection, showHeaderBG }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      site(siteMetadata: {}) {
        siteMetadata {
          title
          siteUrl
        }
      }
      sitePage {
        path
      }
    }
  `)
  const ref = createRef()
  return (
    <>
      {/* Apply new font for Japanese  */}
      {/* {currentLanguage === "jp" ? (<div dangerouslySetInnerHTML={{ __html: style }}></div>) : ("")} */}
      <Header siteTitle={data.site.siteMetadata.title} showHeaderBG={showHeaderBG}/>
      <main ref={ref}>{children}</main>
      <Scroll showBelow={scrollTop} />
      <Footer isShowContact={isShowContactSection} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
