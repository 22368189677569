import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from '@reach/router'
function SEO({
  canonical,
  description,
  lang,
  meta,
  thumbnail,
  metaKeywords,
  title,
  type
}) {

  const location = useLocation();
  const pathname = location.pathname;

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            thumbnail
            siteUrl
            keyWords
          }
        }
      }
    `
  )

  const metaDescription =
    description || site.siteMetadata.description
  const metaThumbnail = thumbnail || site.siteMetadata.thumbnail

  const keywords = metaKeywords || site.siteMetadata.keyWords
  const url = site.siteMetadata.siteUrl
  const defaultTitle = site.siteMetadata?.title
  const arrUrls = [ '/careers' , '/careers/power-bi-engineer', '/careers/data-engineer-intern'
                  , '/careers/ai-intern-dn', '/benefits']

  const regexUrls = [ /^\/blog\/\d+$/, /^\/blog\/\d+\/$/
                    , /^\/categories\/artificial-intelligence\/\d+(\/)?$/
                    , /^\/categories\/machine-learning\/\d+(\/)?$/]

  const isMatchingWithRegexList = (path) => {
    for (let i = 0; i < regexUrls.length; i++) {
        if (regexUrls[i].test(path)) {
            return true;
        }
    }
    return false;
  }

  const titleUpperCase = title.replace(/\w\S*/g, w =>
    w.replace(/^\w/, c => c.toUpperCase())
  )

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleUpperCase}
      titleTemplate={type === "article" ? titleUpperCase : `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaThumbnail,
        },
        {
          property: `og:image:secure_url`,
          content: metaThumbnail,
        },
        {
          property: `og:image:alt`,
          content: titleUpperCase || defaultTitle,
        },
        {
          property: `og:title`,
          content: titleUpperCase || defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type || `website`,
        },
        {
          property: `og:url`,
          content: pathname !== "/" ? `${url}${pathname}` : url,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: titleUpperCase || defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: metaThumbnail,
        },
        {
          name: `robots`,
          content: (arrUrls.includes(pathname) || isMatchingWithRegexList(pathname)) ? `noindex,follow` : `index,follow`,
        },
        {
          name: `keywords`,
          content: keywords,
        },
      ].concat(meta)}
      link={
        canonical ? [{ rel: 'canonical', href: canonical }] : []
      }
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  thumbnail: ``,
  metaKeywords: ``,
}

SEO.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  metaKeywords: PropTypes.string,
}

export default SEO
