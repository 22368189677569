import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Link,
  // useI18next,
  Trans,
  useTranslation,
} from "gatsby-plugin-react-i18next"
import { Navbar, Nav, SplitButton } from "react-bootstrap"
import routes from "../../router"

import "./Navbar.scss"

// import flagEn from "../../assets/icon/flag-en24.png"
// import flagJp from "../../assets/icon/flag-ja24.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import SchemaMarkup from "../Schema/SchemaMarkup"

Header.propTypes = {
  siteTitle: PropTypes.string,
  categories: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default function Header({showHeaderBG}) {
  // const { language } = useI18next()
  const { t } = useTranslation()
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)
  const [expanded, setExpanded] = useState(false)

  useScrollPosition(({ currPos }) => {
    setCurrentScrollPosition(currPos.y)
  })
  const media =
    typeof window !== "undefined"
      ? matchMedia("(max-width: 991px)").matches
      : false

  const [currentMedia, setCurrentMedia] = useState()

  useEffect(() => {
    setCurrentMedia(media)
  }, [media, currentScrollPosition])

  // const currentLanguage = language

  // const multipleLanguageDropdown = (
  //   <React.Fragment>
  //     <img
  //       alt=""
  //       className="mul-language__item__flag-icon"
  //       src={currentLanguage === "jp" ? flagJp : flagEn}
  //     ></img>
  //     <Trans>{currentLanguage}</Trans>
  //   </React.Fragment>
  // )

  function toBlog() {
    window.location.href = "/blog"
  }

  return (
    <header
      className={`header fixed-top ${
        (currentScrollPosition !== 0) & (media === false) || (showHeaderBG === true) ? "animationBG" : ""
      }`}
    >
      <div className="wrapper">
        <SchemaMarkup />
        <Navbar
          expanded={expanded}
          collapseOnSelect
          expand="lg"
          variant="dark"
          className="navbar"
        >
          <Navbar.Brand>
            <Link to="/" className="nav-link__logo">
              <img
                src={process.env.GATSBY_CDN_CONFIG + "/assets/image/icon/neurond-final-white.svg"}
                alt="NeurondAI-logo"
                className="nav-link__logo__image"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="navbar-toggler navbar-toggler-custom"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              {routes.map((item, index) => {
                if (item.active) {
                  if (item.children && !currentMedia && item.showChildren) {
                    return (
                      <div className="dropdown">
                        {item.name === "blog" ? (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Link
                              className="navbar-menu nav-link-name"
                              to="/blog"
                            >
                              <Trans>{item.name}</Trans>
                            </Link>
                            <div className="navbar-menu nav-link-icon">
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                color="white"
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Link
                              to="#"
                              className="navbar-menu nav-link-name custom"
                            >
                              <Trans>{item.name}</Trans>
                            </Link>
                            <div className="navbar-menu nav-link-icon">
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                color="white"
                              />
                            </div>
                          </div>
                        )}
                        <div className="dropdown-content">
                          {item.children.map((child, i) => {
                            if (child.active) {
                              return (
                                <Link
                                  key={i}
                                  to={child.path}
                                  className="sub-menu_service nav-link custom"
                                  active-classname="Active"
                                >
                                  <Trans>{child.name}</Trans>
                                </Link>
                              )
                            }
                            return null
                          })}
                        </div>
                      </div>
                    )
                  } else if (
                    item.children &&
                    currentMedia &&
                    item.showChildren
                  ) {
                    return (
                      <SplitButton
                        key={index}
                        title={t(`${item.name}`)}
                        onClick={item.name === "blog" ? toBlog : null}
                        className="split-button"
                        aria-expanded={false}
                      >
                        {item.children.map((child, i) => {
                          if (child.active) {
                            return (
                              <Link
                                key={i}
                                to={child.path}
                                className="sub-menu_service nav-link"
                                active-classname="Active"
                                onClick={() => setExpanded(false)}
                              >
                                <Trans>{child.name}</Trans>
                              </Link>
                            )
                          }
                          return null
                        })}
                      </SplitButton>
                    )
                  } else if(item.showAsButton) {
                    return (
                      <Link
                        key={index}
                        to={item.path}
                        className="navbar-menu nav-link link-button"
                        active-classname="Active"
                        onClick={() => setExpanded(false)}
                      >
                        <Trans>{item.name}</Trans>
                      </Link>
                    )
                  } else {
                    return (
                      <Link
                        key={index}
                        to={item.path}
                        className="navbar-menu nav-link"
                        active-classname="Active"
                        onClick={() => setExpanded(false)}
                      >
                        <Trans>{item.name}</Trans>
                      </Link>
                    )
                  }
                }
                return null
              })}

              {/* Config multiple language */}
              {/* {currentMedia ? (
              <SplitButton
                title={multipleLanguageDropdown}
                className="split-button"
              >
                {languages
                  .filter(item => item !== currentLanguage)
                  .map(lng => {
                    return (
                      <Link
                        key={lng}
                        to={originalPath}
                        language={lng}
                        className="sub-menu_language nav-link"
                        active-classname="Active"
                      >
                        <img
                          alt=""
                          className="mul-language__item__flag-icon"
                          src={`${lng === "jp" ? flagJp : flagEn}`}
                        />
                        <Trans>{lng}</Trans>
                      </Link>
                    )
                  })}
              </SplitButton>
            ) : (
              <NavDropdown
                title={multipleLanguageDropdown}
                id="collapsible-nav-dropdown"
                className="drop-menu"
                renderMenuOnMount={true}
              >
                {languages
                  .filter(item => item !== currentLanguage)
                  .map(lng => {
                    return (
                      <Link
                        key={lng}
                        to={originalPath}
                        language={lng}
                        className="sub-menu_language nav-link"
                        active-classname="Active"
                      >
                        <img
                          alt=""
                          className="mul-language__item__flag-icon"
                          src={`${lng === "jp" ? flagJp : flagEn}`}
                        ></img>
                        <Trans>{lng}</Trans>
                      </Link>
                    )
                  })}
              </NavDropdown>
            )} */}

              {/* Config multiple language */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  )
}
